import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const CSDiveIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dive - Mode overview</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_diveintro.png"
            alt="Dive introduction"
          />
        </div>
        <div className="page-details">
          <h1>Dive - Mode overview</h1>
          <h2>
            In this guide you will find all the information about the Dive PVE
            mode.
          </h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="What are Dives?" />
        <p>
          Dives are a form of progressive content where you fight through a
          series of nodes containing various enemies until you finally beat the
          boss level.
        </p>
        <p>
          Dives are highly valued by players because of the generous rewards
          that they offer. They give credits, Eternium, Imaginary Cores (which
          can be spent at the Dive shop) and various other rewards depending on
          the difficulty of the Dive.
        </p>
        <p>These are how the Dive nodes generally look like:</p>
        <StaticImage
          src="../../../images/counterside/generic/guide_dive_new_1.jpg"
          alt="Dive Nodes"
        />
        <SectionHeader title="How do I access Dives?" />
        <p>
          Dives are found in the World Map. Once you are at the World Map, you
          can access a series of Dives via this icon at the bottom right of the
          screen:
        </p>
        <StaticImage
          src="../../../images/counterside/generic/dive_depth.png"
          alt="Dive Depth"
        />
        <p>
          You get 2 Dive permits everyday, which can stack up to a maximum of
          10. These permits are used to clear dives within this icon, up to the
          maximum level of 60. Once you have cleared Lvl 60, Dive permits can be
          used for safe mining, where you instantly redeem each permit for 1100
          Eternium and 145 Imaginary Cores.
        </p>
        <p>
          In addition, all your office branches have a chance to spawn a Dive
          after a dispatch mission is finished, like this:
        </p>
        <StaticImage
          src="../../../images/counterside/generic/dive_workwork.png"
          alt="Dive Depth"
        />
        <SectionHeader title="Special nodes to take note of" />
        <p>
          There are nodes with exclamation marks on them. This is to denote a
          “high difficulty” node, which will be more difficult to clear and will
          always reward an artifact upon successful completion. You will be
          given 3 random artifacts to choose from, as shown below:
        </p>
        <StaticImage
          src="../../../images/counterside/generic/guide_dive_new_2.jpg"
          alt="Dive Nodes"
        />
        <p>
          There are also safe nodes, of which there are two different types. One
          heals your ship by 20% of their max hp, while the other gives you 3
          artifacts to choose from.
        </p>
        <p>The ship heal safe node looks like this:</p>
        <StaticImage
          src="../../../images/counterside/generic/dive_guide_sm.jpg"
          alt="Dive Nodes"
        />
        <p>The artifact safe node looks like this:</p>
        <StaticImage
          src="../../../images/counterside/generic/dive_guide_sm2.jpg"
          alt="Dive Nodes"
        />
        <hr />
        <StaticImage
          src="../../../images/counterside/generic/guide_dive_new_4.webp"
          alt="Dive Nodes"
        />
        <SectionHeader title="What do I need to do a Dive successfully?" />
        <p>
          You will need multiple teams of units to attempt a Dive (up to 4
          teams). The lower levels are extremely easy and can be completed with
          barely levelled units, but you will need better built units and a
          decent team comp at higher levels
        </p>
        <p>
          A generic team comp usually consists of 1-2 frontline tanks, 1 healer,
          and 1-2 dps units. Also, you do not need a full team of 8 units to
          begin a dive. A sample team which fulfils these criteria is shown
          below:
        </p>
        <StaticImage
          src="../../../images/counterside/generic/guide_dive_new_5.jpg"
          alt="Dive Nodes"
        />
        <p>
          There is also a support system for Dives, where the assigned leaders
          of other teams can be deployed to assist the team currently in battle.
          These leader support units will be marked with the word “SUPPORT” and
          will appear on the right of your usual unit deployment lineup, as
          shown below:
        </p>
        <StaticImage
          src="../../../images/counterside/generic/guide_dive_new_6.jpg"
          alt="Dive Nodes"
        />
        <p>
          Putting units such as Evelyn (a very strong healer) or A.Hilde (a very
          sturdy frontline tank) as leader supports can greatly help with
          clearing more difficult dive nodes consistently.
        </p>
        <SectionHeader title="Do I have to play this on manual?" />
        <p>
          Dives are reasonably easy to play on full auto. The game will try to
          use as few teams as possible, starting from the first team, and will
          only switch teams if your current team’s ship takes damage and there
          are other teams with ships on higher health (in percentage). If two or
          more teams have ships with the same health percentage, the team with
          the higher ranking will get picked first (e.g., 2nd team will be
          picked over 3rd team if both have ships on 100% hp).
        </p>
        <p>
          We also have dedicated guides for Main Dive Lvls 48-50 and Branch Dive
          Lvl 60, because these Dive levels have special mechanics that can make
          clearing difficult.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Dive - 48-50"
            link="/counter-side/guides/dive-49-50"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dive.png"
                alt="Dive 49 and 50"
              />
            }
          />
          <CategoryCard
            title="Dive - 60"
            link="/counter-side/guides/dive-60"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dive.png"
                alt="Dive 60"
              />
            }
          />
        </Row>
        <p>
          For Dive Lvls 51-55, there will not be a guide dedicated to them
          because they are less heavy on mechanics, but there are still a couple
          of things you should take note of.
        </p>
        <p>
          Clareth (the boss unit for lvl 54 and and a mob unit in the boss node
          for lvl 55) has an ult with an extremely huge AOE, which can CC +
          knockback almost your entire team. You can play around this by:
        </p>
        <ul>
          <li>using shields and heals to just tank the ult</li>
          <li>skill sealing him so he cannot ult</li>
          <li>simply killing him before he can ult.</li>
        </ul>
        <p>
          Ray (the boss unit for lvl 55) gains CC immunity, invincibility while
          walking, immunity to ultimate or lower tier hitstuns, and an enhanced
          basic attack which deals knockback for 20 seconds after he ults.
          Similar to Clareth, you can tank through the ult, skill seal him so he
          cannot ult, or simply try to kill him before he can ult.
        </p>
        <p>
          Do also be aware that the boss nodes (and some of the normal nodes)
          come with a ton of mobs, which can greatly hinder your damage output
          against the actual boss unit.{' '}
        </p>
      </div>
    </DashboardLayout>
  );
};

export default CSDiveIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Dive guide | Counter Side | Prydwen Institute"
    description="Guide for the Dive mode in Counter Side."
  />
);
